import React, {useState} from "react"
import styled from "styled-components";
import {
  Accordion,
} from 'react-accessible-accordion';
import CustomAccordionItem from "./CustomAccordionItem.js";
import ContainerSmall from "../styles/ContainerSmall";
import Performance from "../icons/performance.svg";
import Accessibility from "../icons/accessibility.svg";
import Speed from "../icons/speed.svg";
import cleancatalogPerformance from "../content/comparison-tests/cc-performance.jpg";
import cleancatalogAccessibility from "../content/comparison-tests/cc-accessiblity.png";
import cleancatalogSpeed from "../content/comparison-tests/cc-speed.png";
import kualiPerformance from "../content/comparison-tests/kuali-performance.png";
import kualiAccessibility from "../content/comparison-tests/kuali-accessiblity.png";
import kualiSpeed from "../content/comparison-tests/kuali-speed.png";
import coursedogPerformance from "../content/comparison-tests/coursedog-performance.png";
import coursedogAccessibility from "../content/comparison-tests/coursedog-accessibility.png";
import coursedogSpeed from "../content/comparison-tests/coursedog-speed.png";
import watermarkPerformance from "../content/comparison-tests/watermark-performance.png";
import watermarkAccessibility from "../content/comparison-tests/watermark-accessibility.png";
import watermarkSpeed from "../content/comparison-tests/watermark-speed.png";
import moderncampusPerformance from "../content/comparison-tests/modern-campus-performance.png";
import moderncampusAccessibility from "../content/comparison-tests/moderncampus-accessibility.png";
import moderncampusSpeed from "../content/comparison-tests/moderncampus-speed.png";
import courseleafPerformance from "../content/comparison-tests/courseleaf-performance.png";
import courseleafAccessibility from "../content/comparison-tests/courseleaf-accessibility.png";
import courseleafSpeed from "../content/comparison-tests/coursedog-speed.png";


const FeaturesInterior = styled.div`
  background: #FFF;
  box-shadow: ${props => props.theme.boxShadow};
  // Overlay resources section
  position: relative;
  z-index: 10;
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 100px;
  @media(min-width: ${props => props.theme.screenSm}) {
    padding-top: 80px;
    padding-left: 110px;
    padding-right: 110px;
  }
`;

const ComparisonAccordion = () => {

  const [uuid, setUuid] = useState(1);


  return (

    <FeaturesInterior>
      <ContainerSmall>
        <Accordion
          allowZeroExpanded="1"
          preExpanded="1"
          onChange={(uuid) => setUuid(uuid)}>
          <CustomAccordionItem
            uuid={1}
            currentUuid={uuid}
            headingText={'Performance Comparison'}
            icon={<Performance/>}
            comparisonPanelText={'Lighthouse, a Google tool, measures a site for performance, accessibility, web best practices, and SEO.'}
            cleancatalogImg={cleancatalogPerformance}
            kualiImg={kualiPerformance}
            coursedogImg={coursedogPerformance}
            watermarkImg={watermarkPerformance}
            moderncampusImg={moderncampusPerformance}
            courseleafImg={courseleafPerformance}
            lastUpdated={'Table last updated January 2025'}
          />
          <CustomAccordionItem
            uuid={2}
            currentUuid={uuid}
            headingText={'Accessibility Comparison'}
            icon={<Accessibility/>}
            comparisonPanelText={'Accessibility audit ran with WAVE accessibility tool on catalog homepages from each provider.'}
            cleancatalogImg={cleancatalogAccessibility}
            kualiImg={kualiAccessibility}
            coursedogImg={coursedogAccessibility}
            watermarkImg={watermarkAccessibility}
            moderncampusImg={moderncampusAccessibility}
            courseleafImg={courseleafAccessibility}
            lastUpdated={'Table last updated January 2025'}
          />
          <CustomAccordionItem
            uuid={3}
            currentUuid={uuid}
            headingText={'Speed Comparison'}
            icon={<Speed/>}
            comparisonPanelText={'Speed test done with Pingdom Website Speed Test on catalog homepages from each provider.'}
            cleancatalogImg={cleancatalogSpeed}
            kualiImg={kualiSpeed}
            coursedogImg={coursedogSpeed}
            watermarkImg={watermarkSpeed}
            moderncampusImg={moderncampusSpeed}
            courseleafImg={courseleafSpeed}
            lastUpdated={'Table last updated January 2025'}
          />

        </Accordion>
      </ContainerSmall>
    </FeaturesInterior>
  )
}

export default ComparisonAccordion;
