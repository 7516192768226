import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from "styled-components";
import Container from "../styles/Container";
import StyledH2 from "../styles/StyledH2";
import Button from "../components/Button";
import SoftwareComparisonTable from "../components/ComparisonTable";
import ComparisonAccordion from "../components/ComparisonAccordion";
import Footer from "../components/Footer";
import HeaderLogo from "../components/HeaderLogo";
import ContainerSmall from "../styles/ContainerSmall";

const PageTitle = styled.h1`
    font-size: 60px;
    line-height: 1.3;
`

const Hero = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 70px;
    @media (max-width: ${props => props.theme.screenSm}) {
        flex-direction: column;
        align-items: center;
    }
`

const HeroLeft = styled.div`
    background: #FFF;
    padding-left: 172px;
    padding-right: 172px;
    padding-top: 30px;
    padding-bottom: 80px;
    box-shadow: ${props => props.theme.boxShadow};
    @media (max-width: ${props => props.theme.screenMd}) {
        max-width: 1096px;
        padding-left: 25px;
        padding-right: 25px;
        text-align: center;
    }
`

const HeroRight = styled.div`
    background: ${props => props.theme.red};
    color: #FFF;
    box-shadow: ${props => props.theme.boxShadow};
    display: flex;
    align-items: center;
    padding-right: 172px;
    padding-left: 172px;
    text-align: center;
    margin-left: 20px;
    justify-content: center;
    @media (max-width: ${props => props.theme.screenMd}) {
        max-width: 1096px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 2rem;
    }
    @media (max-width: ${props => props.theme.screenSm}) {
        margin-top: 15px;
        margin-left: 0;
    }
`

const StyledH3Header = styled.h2`
    font-weight: 300;
    font-size: 34px;
`

const StyledTextBlock = styled(ContainerSmall)`

  h2 {
    line-height: 1.4;
  }
  
  
  p {
    padding: 15px 30px;
    
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.4;
  }
`


export default function Index() {

  return (
    <Layout>
      <Container>
        <HeaderLogo/>
        <Hero>
          <HeroLeft>
            <PageTitle>Course Catalog Software Comparison </PageTitle>

          </HeroLeft>
          <HeroRight>
            <div>
              <StyledH3Header>Switch to Clean Catalog Today</StyledH3Header>
              <Button
                path={'https://cleancatalog.com/start'}
                text={'Get Started'}
              />
            </div>
          </HeroRight>
        </Hero>

        <StyledTextBlock>

          <StyledH2>Compare Modern Campus (Acalog), Watermark (Smart Catalog), Kuali Catalog, Course Dog Catalog, Course Leaf, and Clean Catalog</StyledH2>
          <p>When it comes to course catalog software, institutions need more than just functionality—they need a solution that’s affordable, accessible, and intuitive for administrators, faculty, and students alike. Clean Catalog delivers all this and more, making it the best choice for schools and universities.</p>
            <p>While other providers like CourseDog, Kuali, Watermark, Modern Campus, and CourseLeaf offer some of these features, Clean Catalog stands out for its affordability, ease of use, and exceptional customer support. No hidden fees, no complex contracts—just straightforward, high-quality service.</p>



        </StyledTextBlock>
        <SoftwareComparisonTable/>
        <ComparisonAccordion/>

        <StyledTextBlock>


          <p>Regardless of what catalog software you're currently using, we will migrate your content to Clean Catalog
            software
            for free. Not only do we not have start-up fees, but our annual rates are typically much more affordable
            than
            other providers. With our cutting-edge features and budget-friendly pricing, we frequently work with both
            large
            public universities and small community colleges alike, as well as everyone in between.</p>

          <p>But don’t take our word for it! Schedule a free demo of our software or read one of our case studies below
            to learn more.</p>

          <p> <a href={"https://cleancatalog.com/case-studies/coastalpines-smart-catalog/"}>Read our case study on switching from Watermark to Clean Catalog.</a></p>
          <p>
            <a href={"https://cleancatalog.com/case-studies/sage-college/"}>Read our case study on switching from Acalog to Clean Catalog.</a>
          </p>

        </StyledTextBlock>

      </Container>
      <Footer/>
    </Layout>
  )
}

export const Head = (props) => {
  return (
    <Seo
      canonical={props.location.pathname}
    />
  )
};
