const theme = {
  blue: '#0D3745',
  teal: '#3a7474',
  red: '#D74339',
  black: '#141414',
  gray: '#C8D7D2',
  screenSm: '768px',
  screenSmMax: '767px',
  screenMd: '1368px',
  screenMdMax: '1367px',
  boxShadow: '7px 12px 35px rgba(0, 0, 0, 0.1)',
  transition: 'all, ease-in-out, 300ms',
  fontXl: '2.2rem',
  fontLg: '1.75rem',
  fontMd: '1.5rem',
  fontMdSm: '1.25em'
};

export default theme;
