import React from "react"
import styled from "styled-components";
import Checkmark from "../icons/checkmark.svg";
import ContainerSmall from "../styles/ContainerSmall";

const ComparisonTable = styled.table`
    color: #333;
    margin: 4vh 0;
       @media (max-width: ${props => props.theme.screenMd}) {
        display: block;
        width: 100%;
        overflow-x: scroll;
    }
`

const TableHeader = styled.th`
    font-weight: 700;
    height: 20px;
    text-align: center;
    font-size: 1.2em;
    
    :nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7) {
        width: 12%;
    }
`

const RowHeader = styled.td`
    font-weight: bold;
    height: 20px;
    border-top: 1px solid #000;

    :nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7) {
        border-left: 1px solid #000;
    }
`

const TableData = styled.td`
    height: 20px;

    :nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7) {
        border-left: 1px solid #000;
        text-align: center;
    }
    
`

export default function SoftwareComparisonTable() {

  return (
    <ContainerSmall>
      <ComparisonTable cellSpacing="0" cellPadding="10px">
        <thead>
        <tr>
          <TableHeader>&nbsp;</TableHeader>
          <TableHeader>Clean Catalog</TableHeader>
          <TableHeader>Kuali</TableHeader>
          <TableHeader>Course Dog</TableHeader>
          <TableHeader>Watermark (Smart Catalog)</TableHeader>
          <TableHeader>Modern Campus (Acalog)</TableHeader>
          <TableHeader>Course Leaf</TableHeader>

        </tr>
        </thead>
        <tbody>
        <tr>
          <RowHeader>Competitive Pricing</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
        </tr>
        <tr>
          <TableData>No Startup Fee</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>
        <tr>
          <TableData>Initial Content Import and Technical Setup Done for You with No Additional Cost</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>
        <tr>
          <TableData>Affordable Pricing for Schools of all Sizes</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>

        <tr>
          <RowHeader>Intuitive Content Editing</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
        </tr>
        <tr>
          <TableData>Automated PDF Generation of Every Page and Entire Catalog</TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>
        <tr>
          <TableData>Support for Degrees, Courses, Text Pages</TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
        </tr>
        <tr>
          <TableData>Addenda Feature</TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
        </tr>
        <tr>
          <TableData>WCAG 2.2 AA Accessibility Compliance Out of the Box</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>
        <tr>
          <TableData>Workflows and Editorial Management</TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData><Checkmark/></TableData>
        </tr>
        <tr>
          <TableData>Revisions Portal to View and Revert Past Content Versions</TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData><Checkmark/></TableData>
        </tr>


        <tr>
          <RowHeader>Integration Support</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
        </tr>

        <tr>
          <TableData>Integration with Student Information Systems/Websites </TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
        </tr>
        <tr>
          <TableData>Easy-to-Use (and Free) API for Pulling Catalog Information</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>

        <tr>
          <RowHeader>Superior Website Experience</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
        </tr>
        <tr>
          <TableData>Customizable In-Catalog Search</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>
        <tr>
          <TableData>Designed Around SEO Best Practices</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>
        <tr>
          <TableData>Fully Customizable Design at No Additional Cost</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>
        <tr>
          <TableData>Add Additional Handbooks for a Low Cost</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>
        <tr>
          <TableData>Page Load Times Under One Second</TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
        </tr>
        <tr>
          <TableData>Responsive Design for Phones, Tablets, and Screens of All Sizes</TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData><Checkmark/></TableData>
        </tr>
        <tr>
          <RowHeader>Highly Secure</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
          <RowHeader>&nbsp;</RowHeader>
        </tr>
        <tr>
          <TableData>SSL/HTTPS Security by Default on All Catalogs</TableData>
          <TableData><Checkmark/></TableData>
          <TableData><Checkmark/></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData></TableData>
          <TableData><Checkmark/></TableData>
        </tr>
        </tbody>
      </ComparisonTable>
    </ContainerSmall>
  )
}