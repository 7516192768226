import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from "styled-components";


const PostWrapper = styled.article`
  max-width: 45rem;
  margin: 60px auto;
  padding: 15px;
  a {
    color: ${props => props.theme.red};
  }
`;

export default function four(props) {
  return (
  <Layout location={props.location} >
    <Seo title="404: Not Found"/>
    <PostWrapper>
      <h1>Page Not Found</h1>
      <p>Looks like that page doesn't exist. We're aware of the error, but if you're looking for something you can't
        find feel free to <a href='https://cleancatalog.com/contact'>contact us</a>.</p>
    </PostWrapper>
  </Layout>
  )
}
