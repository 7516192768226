import React from "react"


import {ThemeProvider} from "styled-components";
import Theme from "../styles/Theme";
import {createGlobalStyle} from "styled-components";
import "@fontsource/roboto/900.css";
import "@fontsource/nunito-sans/300.css" ;
import "@fontsource/nunito-sans/600.css" ;
import "@fontsource/nunito-sans/800.css" ;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nunito Sans', sans-serif;
    background-color: #F3F2F0;
    margin: 0;
  }
  
  a {
    color: ${props => props.theme.blue};
  }
`;


export default function Layout(props) {

  return (
    <div id={'page-wrapper'}>
      <ThemeProvider theme={Theme}>
        <GlobalStyle/>

        <main>
          {props.children}
        </main>


      </ThemeProvider>
    </div>
  )
}
