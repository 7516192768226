import React from 'react';
import styled from "styled-components";
import Logo from "../content/assets/logo.png";


const LogoImg = styled.img`
    margin-top: 4rem;
`

const HeaderLogo = () => {
    return (
        <a href="https://cleancatalog.com">
            <LogoImg src={Logo} alt="Clean Catalog Logo" />
        </a>
    )
}

export default HeaderLogo;