import styled from "styled-components";

const ContainerSmall = styled.div`
  margin: 0 auto;
  max-width: 1096px;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default ContainerSmall;
