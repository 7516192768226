import React from "react"



function Seo({}) {

  const title = 'Course Catalog Software Comparison';
  const metaDescription = 'Compare Modern Campus Acalog, Watermark Smart Catalog, and Clean Catalog';
  const url = 'https://coursecatalogsoftware.com';
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="og:type" content={metaDescription} />
      <meta name="twitter:title" content={title} />
      <meta name="og:title" content={title} />
      <meta property="og:url" content={url} />
      <link rel="canonical" href={url} />
    </>
  )
}


export default Seo
