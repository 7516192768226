import React from "react"

import styled from "styled-components";

import 'react-tabs/style/react-tabs.css';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Collapse from "../icons/collapse.svg";
import Expand from "../icons/expand.svg";

const ComparisonButton = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #000;
  justify-content: space-between;

  svg {
    padding-right: 1rem;
  }
`

const ComparisonPanel = styled(AccordionItemPanel)`
  padding-top: 1rem;
`

const HeadingText = styled.h3`
  font-size: 2.1em;
  font-weight: 300;
  letter-spacing: 3px;
`

const Column = styled.div`
  width: 50%;
  padding: 1rem;
    box-sizing: border-box;
  @media (max-width: ${props => props.theme.screenMd}) {
    width: 50%;
  }
  @media (max-width: ${props => props.theme.screenSm}) {
    width: 95%;
  }
`

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  @media (max-width: ${props => props.theme.screenMd}) {
    flex-direction: column;
    align-items: center;
  }
`

const LastUpdated = styled.div`
  margin-top: 1rem;
  font-style: italic;
`

const Provider = styled.h3`
  font-weight: 800;
`

const School = styled.div``

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
`

const ComparisionImg = styled.img`
  width: 100%;
  height: auto;
  box-shadow: ${props => props.theme.boxShadow};
`

const IntroText = styled.div`
  font-size: 1.2em;
`

const CustomAccordionItem = (props) => {


  // Determine the icon
  let icon = <Expand />;
  console.log(props);
  if(props.currentUuid === props.uuid) {
    icon = <Collapse />;
  }


  return (
    <AccordionItem
      uuid={props.uuid}
    >
      <AccordionItemHeading>
        <ComparisonButton>
          <HeadingText>
            {props.icon}
            {props.headingText}
          </HeadingText>
          {icon}
        </ComparisonButton>
      </AccordionItemHeading>
      <ComparisonPanel>
        <IntroText>{props.comparisonPanelText}</IntroText>
        <Columns>
          <Column>
            <HeaderWrapper>
              <Provider>Clean Catalog</Provider>
              <School>Audit of <a href="https://catalog.wvc.edu/">WVC</a>, a Clean Catalog-based catalog </School>
            </HeaderWrapper>
            <ComparisionImg src={props.cleancatalogImg} alt={'Audit of WVC, a Clean Catalog-based catalog'}/>
            {props.cleanCatalogData}
          </Column>
          <Column>
            <HeaderWrapper>
              <Provider>Kuali</Provider>
              <School>Audit of <a href="https://catalog.colostate.edu/general-catalog/courses-az/">Colorado State University</a>, a Kuali-based catalog </School>
            </HeaderWrapper>
            <ComparisionImg src={props.kualiImg} alt={'Audit of Colorado State University, a Kuali-based catalog'} />
            {props.kualiData}
          </Column>
          <Column>
            <HeaderWrapper>
              <Provider>Coursedog</Provider>
              <School>Audit of <a href="https://catalog.brookdalecc.edu/">Brookdale Community College</a>, a Coursedog-based catalog </School>
            </HeaderWrapper>
            <ComparisionImg src={props.coursedogImg} alt={'Audit of Brookdale Community College, a Coursedog-based catalog'}/>
            {props.coursedogData}
          </Column>
          <Column>
            <HeaderWrapper>
              <Provider>Watermark</Provider>
              <School>Audit of <a href="https://westliberty.smartcatalogiq.com/en/2024-2025/undergraduate-catalog/">West Liberty University</a>, a Watermark-based catalog </School>
            </HeaderWrapper>
            <ComparisionImg src={props.watermarkImg} alt={'Audit of West Liberty University, a Watermark-based catalog'} />
            {props.watermarkData}
          </Column>
          <Column>
            <HeaderWrapper>
              <Provider>Modern Campus</Provider>
              <School>Audit of <a href="https://catalog.furman.edu/content.php?catoid=20&navoid=1002">Furman University</a>, a Modern Campus-based catalog </School>
            </HeaderWrapper>
            <ComparisionImg src={props.moderncampusImg} alt={'Audit of Furman University, a Modern Campus-based catalog'}/>
            {props.moderncampusData}
          </Column>
          <Column>
            <HeaderWrapper>
              <Provider>Course Leaf</Provider>
              <School>Audit of <a href="https://catalog.wesleyan.edu/courses/">Wesleyan University</a>, a Course Leaf-based catalog </School>
            </HeaderWrapper>
            <ComparisionImg src={props.courseleafImg} alt={'Audit of Wesleyan University, a Course Leaf-based catalog'}/>
            {props.courseleafData}
          </Column>
        </Columns>
        <LastUpdated>{props.lastUpdated}</LastUpdated>
      </ComparisonPanel>
    </AccordionItem>

  )
}

export default CustomAccordionItem;
