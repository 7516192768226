import React from "react"
import styled from "styled-components";
import ContainerSmall from "../styles/ContainerSmall";
import Container from "../styles/Container";
import Link from "gatsby-link";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Form from "./Form";

const FooterWrapper = styled.footer`
  background: #0D3745;
  position: relative;
  z-index: 10;
  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    height: 50px;
    left: 0;
    right: 0;
    top: 0;
    // See blog page for how this is used
    background: #F3F2F0;
  }
`;

const FooterUpper = styled(Container)`
  background: #fff;
  padding-top: 56px;
  padding-bottom: 51px;
  padding-left: 0;
  padding-right: 0;
  margin-top: 56px;
  box-shadow: ${props => props.theme.boxShadow};
  position: relative;
  z-index: 20;
  h2 {
    margin-top: 24px; // aligns with form element to the right
    margin-bottom: 15px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 2.875rem;
    line-height: 54/46;
    letter-spacing: 0.03em;
    color: #000000;
    @media (max-width: ${props => props.theme.screenSmMax}) {
      font-size: 1.5rem;
      margin-top: 0;
    }
  }
  p {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.375rem;
    line-height: 150%;
    color: #000000;
    @media (max-width: ${props => props.theme.screenSmMax}) {
      font-size: 1rem;
    }
    a {
      color: ${props => props.theme.blue};
    }
  }
`

const StyledContainerSmall = styled(ContainerSmall)`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.screenSmMax}) {
    flex-direction: column;
  }
  > div {
    width: 100%;
    max-width: 550px;
    &:first-child {
      @media (min-width: ${props => props.theme.screenSm}) {
        width: 450px;
      }
    }
  }
`

const FooterLower = styled(Container)`
  padding-top: 30px;
  padding-bottom: 33px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.screenSmMax}) {
    flex-direction: column;
  }
  span {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 22/16;
    color: #FFFFFF;
  }
`

const FooterLowerLeft = styled.div`
  flex-direction: column;
  display: flex;
  a {
    color: #fff;
    margin-bottom: .5em;
    &:first-of-type {
      margin-top: .5rem;
    }
  }
`

const FooterLowerRight = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.screenSmMax}) {
    flex-direction: column;
    margin-top: 10px;
  }
`
const SocialWrapper = styled.div`
  display: flex;
`


const FooterLinks = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.screenSmMax}) {
    flex-direction: column;
    margin-bottom: 10px;
  }
  a {
    color: #fff;
    text-decoration: none;
    margin-right: 40px;
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 22/16;
    letter-spacing: 0.03em;
    @media (max-width: ${props => props.theme.screenSmMax}) {
      padding: 15px 15px 15px 0;
    }
  }`

const SocialLink = styled.a`
  margin-right: 11px;
  &:last-child {
    margin-right: 0;
  }
  span {
    // Copy of bootstrap SR only class
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  @media (max-width: ${props => props.theme.screenSmMax}) {
    // Make these large enough that they pass Lighthouse SEO tap target criteria
    width: 48px;
    height: 48px;
    margin: 15px 0;
    display: inline-flex;
  }
`

const Footer = (props) => {

    const data = useStaticQuery(graphql`{
  linkedin: file(absolutePath: {regex: "/linkedin.png/"}) {
    childImageSharp {
      gatsbyImageData(width: 25, height: 25, layout: FIXED)
    }
  }
  twitter: file(absolutePath: {regex: "/twitter.png/"}) {
    childImageSharp {
      gatsbyImageData(width: 25, height: 25, layout: FIXED)
    }
  }
  facebook: file(absolutePath: {regex: "/facebook.png/"}) {
    childImageSharp {
      gatsbyImageData(width: 12, height: 25, layout: FIXED)
    }
  }
}
`)

    const year = new Date().getFullYear();

    return (
        <FooterWrapper grayFooter={props.grayFooter}>
            <FooterUpper>
                <StyledContainerSmall>
                    <div>
                        <h2>Want to learn more?</h2>
                        <p>Schedule a demo, request a price quote, or ask us anything. You can also email us at <a
                            href={'mailto:info@cleancatalog.com.'}>info@cleancatalog.com</a>.</p>
                    </div>
                    <div>
                        <Form/>
                    </div>
                </StyledContainerSmall>

            </FooterUpper>
            <FooterLower>
                <FooterLowerLeft>
                    <span>© {year} Clean Catalog</span>
                    <a href={"tel:1-800-648-7146"}>1-800-648-7146</a>
                    <a href={"mailto:info@cleancatalog.com"}>info@cleancatalog.com</a>
                    <address>
                        1752 NW Market Street #4305<br/>
                        Seattle, WA 98107
                    </address>

                </FooterLowerLeft>
                <FooterLowerRight>
                    <FooterLinks>
                        <Link to={'https://cleancatalog.com/contact/'}>Contact</Link>
                        <Link to={'https://cleancatalog.com/scholarship/'}>Scholarship</Link>
                        <Link to={'https://cleancatalog.com/privacy/'}>Privacy Policy</Link>
                    </FooterLinks>
                    <SocialWrapper>
                        <SocialLink href="https://linkedin.com/company/clean-catalog" target="_blank" rel="noopener noreferrer">
                            <GatsbyImage
                                image={getImage(data.linkedin)}
                                alt="Follow Clean Catalog on LinkedIn" />
                            <span>Follow Clean Catalog on LinkedIn</span>
                        </SocialLink>
                        <SocialLink href="https://twitter.com/CatalogClean" target="_blank" rel="noopener noreferrer">
                            <GatsbyImage
                                image={getImage(data.twitter)}
                                alt="Follow Clean Catalog on Twitter" />
                            <span>Follow Clean Catalog on Twitter</span>
                        </SocialLink>

                        <SocialLink href="https://www.facebook.com/cleancatalogsoftware/" target="_blank" rel="noopener noreferrer">
                            <GatsbyImage
                                image={getImage(data.facebook)}
                                alt="Follow Clean Catalog on Facebook" />
                            <span>Follow Clean Catalog on Facebook</span>
                        </SocialLink>
                    </SocialWrapper>
                </FooterLowerRight>
            </FooterLower>
        </FooterWrapper>
    );
}

export default Footer;