import React from "react"
import styled from "styled-components";
import {Link} from "gatsby";
import Arrow from "../icons/arrow.svg";


const ButtonLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.teal};
  color: #fff;
  text-decoration: none;
  width: ${props => props.width ? props.width : "9.5rem"}; // So we can keep this static on hover
  height: 3.0625rem;
  position: relative;
  font-weight: bold;
  margin: auto;
  span {
    transition: all, ease-in-out, 300ms;
    position: absolute;
    left: 30px;
  }
  svg {
    opacity: 0;
    transition: all, ease-in-out, 300ms;
    position: absolute;
    right: 30px;
    fill: #fff;
    path {
      fill: #fff;
    };
  }
  &:hover,
  &:focus {
    font-weight: bold;
    background: linear-gradient(0deg, rgba(26, 26, 26, 0.2), rgba(26, 26, 26, 0.2)), #D74339;
    svg {
      fill: #FFF;
      opacity: 1;
      right: 15px
    }
    span {
      left: 15px;
    }
  }
 `;

const Button = (props) => {
    return (
        <ButtonLink to={props.path} width={props.width}>
            <span>{props.text}</span>
            <Arrow/>
        </ButtonLink>
    )
}

export default Button;